import {
  MdOutlineDashboard,
  MdOutlinePerson,
  MdOutlineStorefront,
} from "react-icons/md";
import { FaSquarePlus, FaPersonCircleQuestion } from "react-icons/fa6";
import { AiFillGolden } from "react-icons/ai";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { LuMousePointer2 } from "react-icons/lu";
const sidebarConfig = [
  {
    path: "/console/dashboard",
    icon: <MdOutlineDashboard />,
    text: "டாஷ்போர்டு",
  },
  {
    path: "/console/olddata",
    icon: <MdOutlineDashboard />,
    text: "Old Record",
  },
  {
    path: "/console/user",
    icon: <MdOutlinePerson />,
    text: "பயனர் & அணுகல்",
  },
  {
    path: "/console/company",
    icon: <MdOutlineStorefront />,
    text: "நிறுவனம்",
  },
  {
    text: "Master",
    icon: <FaSquarePlus />,
    subMenu: [
      {
        path: "/console/master/group",
        text: "Group"
      },
      {
        path: "/console/master/products",

        text: "Products",
      },
      {
        path: "/console/master/customer",

        text: "Customer",
      },
      {
        path: "/console/master/Street",

        text: "Street",
      },
    ],
  },
  {
    text: "அடகு",
    icon: <AiFillGolden />,
    subMenu: [
      {
        path: "/console/pawn/jewelpawning",
        icon: <AiFillGolden />,
        text: "நகை அடகு",
      },
      {
        path: "/console/pawn/jewelpawng",
        icon: <AiFillGolden />,
        text: "நகை அடகு - G",
      },
    ],
  },
  {
    path: "/console/interest",
    icon: <LuMousePointer2 />,
    text: "வட்டி",
  },
  {
    text: "மீட்பு",
    icon: <RiDeviceRecoverLine />,
    subMenu: [
      {
        path: "/console/master/jewelrecovery",
        text: "நகை மீட்பு",
      },
      {
        path: "/console/master/jewelrecoveryg",
        text: "நகை மீட்பு - G",
      },
    ],
  },
  {
    path: "/console/master/jewelestimate",
    icon: <FaPersonCircleQuestion />,
    text: "எஸ்டிமேட்",
  },
  {
    text: "அறிக்கைகள்",
    icon: <FaSquarePlus />,
    subMenu: [
      {
        path: "/console/report/pawn",
        text: "Pawn",
      },
      {
        path: "/console/report/pawng",
        text: "Pawn - G",
      },
      {
        path: "/console/report/oldrecord",
        text: "Old Record",
      },
    ],
  },
];

export default sidebarConfig;
