import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer';
import fontBold from './fonts/NotoSansTamil-Bold.ttf';
import fontRegular from './fonts/NotoSansTamil-Regular.ttf';

// Register fonts
Font.register({
  family: 'fontBold',
  fonts: [{ src: fontBold, fontStyle: 'normal', fontWeight: 'bold' }],
});
Font.register({
  family: 'fontRegular',
  fonts: [{ src: fontRegular, fontStyle: 'normal', fontWeight: 'normal' }],
});

// Define styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'landscape',
    backgroundColor: '#fff',
    padding: 20,
  },
  section: {
    margin: 10,
    flexGrow: 1,
  },
  font: {
    fontFamily: 'fontRegular',
  },
  tablehead: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    marginBottom: 0,
    
  },
  tablebody: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
    paddingTop: 0, 
    paddingBottom: 0,
    marginBottom: 0, 
  },
  cell: {
    padding: 5,
    fontSize: 10,
    borderWidth: 0.5,
    borderColor: '#000',
  },
  dateTitle: {
    width: '10%',
    textAlign: 'center',
  },
  csDetailsTitle: {
    width: '20%',
    textAlign: 'center',
  },
  receiptNoTitle: {
    width: '7%',
    textAlign: 'center',
  },
  amountTitle: {
    width: '10%',
    textAlign: 'center',
  },
  interstTitle: {
    width: '6%',
    textAlign: 'center',
  },
  podTitle: {
    width: '18%',
    textAlign: 'center',
  },
  countTitle: {
    width: '6%',
    textAlign: 'center',
  },
  weightTitle: {
    width: '6%',
    textAlign: 'center',
  },
  costTitle: {
    width: '6%',
    textAlign: 'center',
  },
  recoverdateTitle: {
    width: '11%',
    textAlign: 'center',
  },
});

// Component definition
const Report = ({ data }) => (
  <Document>
    <Page size="A4" orientation='landscape' style={styles.page}>
      <View style={styles.section}>
        <View style={styles.font}>
          {/* Table Header */}
          <View style={styles.tablehead}>
            <Text style={[styles.cell, styles.dateTitle]}>Date</Text>
            <Text style={[styles.cell, styles.csDetailsTitle]}>Customer Details</Text>
            <Text style={[styles.cell, styles.receiptNoTitle]}>Receipt No.</Text>
            <Text style={[styles.cell, styles.amountTitle]}>Amount</Text>
            <Text style={[styles.cell, styles.interstTitle]}>Interest</Text>
            <Text style={[styles.cell, styles.podTitle]}>Products</Text>
            <Text style={[styles.cell, styles.countTitle]}>Count</Text>
            <Text style={[styles.cell, styles.weightTitle]}>Weight</Text>
            <Text style={[styles.cell, styles.costTitle]}>Cost</Text>
            <Text style={[styles.cell, styles.recoverdateTitle]}>Recovery Date</Text>
          </View>
          {/* Table Body */}
          {data.map((item, index) => (
            <View style={styles.tablebody} key={index}>
              <Text style={[styles.cell, styles.dateTitle]}>{item.pawnjewelry_date}</Text>
              <View style={[styles.cell, styles.csDetailsTitle]}>
                <Text>{item.customer_name}, {item.name_of_guardians}</Text>
                <Text>{item.mobile_number}</Text>
                <Text>{item.address}</Text>
              </View>
              <Text style={[styles.cell, styles.receiptNoTitle]}>{item.recipt_no}</Text>
              <Text style={[styles.cell, styles.amountTitle]}>{item.jewel_original_rate}</Text>
              <Text style={[styles.cell, styles.interstTitle]}>{item.pawn_interest}</Text>
              <View style={[styles.cell, styles.podTitle]}>
                {item.products.map((product, idx) => (
                  <Text key={idx}>{product.JewelName}, {product.weight} gram, {product.count}</Text>
                ))}
              </View>
              <Text style={[styles.cell, styles.countTitle]}>
                {item.products.reduce((total, product) => total + product.count, 0)}
              </Text>
              <Text style={[styles.cell, styles.weightTitle]}>
                {item.products.reduce((total, product) => total + parseFloat(product.weight), 0).toFixed(2)} gram
              </Text>
              <Text style={[styles.cell, styles.costTitle]}>{item.pawn_rate}</Text>
              <Text style={[styles.cell, styles.recoverdateTitle]}>{item.pawnjewelry_recovery_date}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default Report;
