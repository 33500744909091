import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageNav from "../../components/PageNav";
import { FileUpload, TextArea, TextInputForm } from "../../components/Forms";
import { TiTimes } from "react-icons/ti";
import { ClickButton } from "../../components/Buttons";
import { MdOutlineUploadFile } from "react-icons/md";
const CustomerCreations = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="12" className="py-3">
            <PageNav pagetitle="Customer Creation" />
          </Col>
          <Col lg="3" className="py-3">
            <TextInputForm labelname="வாடிக்கையாளர் பெயர்*" />
          </Col>
          <Col lg="3" className="py-3">
            <TextInputForm labelname="தொலைபேசி எண்.*" />
          </Col>
          <Col lg="3" className="py-3">
            <TextInputForm labelname="தந்தை அல்லது கணவர் பெயர்" />
          </Col>
          <Col lg="3" className="py-3">
            <TextArea label="முகவரி" placeholder="முகவரி" />
          </Col>
          <Col lg="3" className="py-3">
            <div className="d-flex proof-upload">
              <div>
                <FileUpload label="Upload a Proof" />
              </div>
              <div className="upload-proof">
                <span className="wrong">
                  <TiTimes />
                </span>
                <MdOutlineUploadFile className="file-icon" size={40} />
              </div>
            </div>
          </Col>
          <Col lg="12" className="py-3 text-center">
            <ClickButton label="Submit"/>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerCreations;
