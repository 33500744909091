import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Calender, TextArea, TextInputForm } from "../../components/Forms";
import PageNav from "../../components/PageNav";
import { ClickButton } from "../../components/Buttons";
import Dialog from "../../components/Dialog";

const OldData = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleShowDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmSubmit = (confirm) => {
    setShowConfirmDialog(false);
    if (confirm) {
      console.log('Form submitted');
      // Add form submission logic here
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="12" className="py-3">
            <PageNav pagetitle="Old Record" />
          </Col>
          <Col lg="4" className="py-3">
            <Calender calenderlabel="தேதி" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Bill No." />
          </Col>
          <Col lg="4" className="py-3">
            <TextArea placeholder="Customer Details" label="Customer Details" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Pawn Amount" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Interest Amount" />
          </Col>
          <Col lg="4" className="py-3">
            <TextArea placeholder="Jewel Details" label="Jewel Details" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Count" placeholder="count" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Weight" placeholder="Weight" />
          </Col>
          <Col lg="4" className="py-3">
            <TextInputForm labelname="Amount" placeholder="Amount" />
          </Col>
          <Col lg="4" className="py-3">
            <Calender calenderlabel="Recovery Date" />
          </Col>
          <Col lg="12" className="py-3">
            <div className="text-center">
              <ClickButton label="Submit" onClick={handleShowDialog} />
            </div>
          </Col>
        </Row>
      </Container>
      <Dialog
        DialogTitle="Do You Want to Submit the Form?"
        isVisible={showConfirmDialog}
        onConfirm={() => handleConfirmSubmit(true)}
        onCancel={() => handleConfirmSubmit(false)}
      />
    </div>
  );
};

export default OldData;
