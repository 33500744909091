// src/routesConfig.js
/////////////////////// creations///////////////////////////////////
import CompanyCreation from "../pages/company/CompanyCreation";
import JewelPawningCreation from "../pages/jewelpawn/JewelPawningCreation";
import UserCreation from "../pages/users/UserCreation";
import JewelRecoveryCreation from "../pages/recovery/JewelRecoveryCreation";
import JewelRecoveryGCreation from "../pages/jewelpawng/JewelPawnGCreation";
import JewelEstimateCreation from "../pages/estimate/JewelEstimateCreation";
import GroupCreation from "../pages/group/GroupCreation";
import InterestCreation from "../pages/interst/InterestCreation";
import CustomerCreations from "../pages/customer/CustomerCreations";
///////////////////// Pdf preview /////////////////////////////
import JewelPawnPreview from "../pdf/JewelPawnPreview";
import ReportPreview from "../pdf/ReportPreview";
import JewelPawngPreview from "../pdf/JewelPawngPreview";
/////////////////////////// reports///////////////////////////////////
import OldRecord from "../pages/oldrecord/OldRecord";
import PawnGReport from "../pages/jewelpawng/PawnGReport";
import OverallReport from "../pages/OverallReport";
import PawnReport from "../pages/jewelpawn/PawnReport";
import RecoveryReport from "../pages/recovery/RecoveryReport";
import InterstReport from "../pages/interst/InterstReport";

/////////////////////////////// list ///////////////////////////////
import DashBoard from "../pages/DashBoard";
import User from "../pages/users/User";
import Company from "../pages/company/Company";
import Group from "../pages/group/Group";
import JewelPawning from "../pages/jewelpawn/JewelPawning";
import JewelRecovery from "../pages/recovery/JewelRecovery";
import JewelRecoveryG from "../pages/jewelpawng/JewelRecoveryG";
import JewelPawnG from "../pages/jewelpawng/JewelPawnG";
import JewelEstimate from "../pages/estimate/JewelEstimate";
import Interest from "../pages/interst/Interest";
import Customer from "../pages/customer/Customer";
import OldData from "../pages/oldrecord/OldData";
import CustomerDetails from "../pages/customer/CustomerDetails";
import Products from "../pages/products/Products";
import Street from "../pages/street/Street";

const routes = [
  { path: "/console/dashboard", element: <DashBoard /> },
  { path: "/console/user", element: <User /> },
  { path: "/console/user/create", element: <UserCreation /> },
  { path: "/console/olddata", element: <OldData /> },
  { path: "/console/company", element: <Company /> },
  { path: "/console/company/create", element: <CompanyCreation /> },
  { path: "/console/pawn/jewelpawning", element: <JewelPawning /> },
  {
    path: "/console/pawn/jewelpawning/create",
    element: <JewelPawningCreation />,
  },
  { path: "/console/pawn/jewelpawng", element: <JewelPawnG /> },
  {
    path: "/console/pawn/jewelpawng/create",
    element: <JewelPawningCreation />,
  },
  { path: "/console/interest", element: <Interest /> },
  { path: "/console/interest/create", element: <InterestCreation /> },
  { path: "/console/master/jewelrecovery", element: <JewelRecovery /> },
  {
    path: "/console/master/jewelrecovery/create",
    element: <JewelRecoveryCreation />,
  },
  { path: "/console/master/jewelrecoveryg", element: <JewelRecoveryG /> },
  {
    path: "/console/master/jewelrecoveryg/create",
    element: <JewelRecoveryGCreation />,
  },
  { path: "/console/master/jewelestimate", element: <JewelEstimate /> },
  {
    path: "/console/master/jewelestimate/create",
    element: <JewelEstimateCreation />,
  },
  { path: "/console/master/group", element: <Group /> },
  { path: "/console/master/group/create", element: <GroupCreation /> },
  { path: "/console/master/customer", element: <Customer /> },
  { path: "/console/master/customerdetails", element: <CustomerDetails /> },
  { path: "/console/report/overall", element: <OverallReport /> },
  { path: "/console/report/pawn", element: <PawnReport /> },
  { path: "/console/report/pawng", element: <PawnGReport /> },
  { path: "/console/report/recovery", element: <RecoveryReport /> },
  { path: "/console/report/interst", element: <InterstReport /> },
  { path: "/console/report/oldrecord", element: <OldRecord /> },
  { path: "/console/jewelpawn", element: <JewelPawnPreview /> },
  { path: "/console/jewelpawng", element: <JewelPawngPreview /> },
  { path: "/console/report", element: <ReportPreview /> },
  { path: "/console/master/customer/create", element: <CustomerCreations /> },
  { path: "/console/master/products", element: <Products /> },
  { path: "/console/master/products", element: <Products /> },
  { path: "/console/master/street", element: <Street /> },
];

export default routes;
