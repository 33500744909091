import React, { useState } from 'react';
import { Col, Container, Row, Alert, Modal } from 'react-bootstrap';
import { TextInputForm, DropDownUI } from '../../components/Forms';
import { VscEyeClosed, VscEye } from 'react-icons/vsc';
import { ClickButton } from '../../components/ClickButton';
import PageNav from '../../components/PageNav';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_DOMAIN from '../../config/config';
const DropList = [
  {
    value: 'அட்மின்',
    label: 'அட்மின்'
  },
  {
    value: 'சூப்பர் அட்மின்',
    label: 'சூப்பர் அட்மின்'
  },
  {
    value: 'பணியாளர்',
    label: 'பணியாளர்'
  }
];
const UserCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState = type === 'edit' ? { ...rowData } : {
    Name: '',
    RoleSelection: '',
    Mobile_Number: '',
    User_Name: '',
    Password: '',
    nickname : ''
  };
  const [formData, setFormData] = useState(initialState);

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
 
  const navigate = useNavigate();

  const redirectModal = () => {
    navigate('/console/user');
  };

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value
    });
  };

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    for (const key in formData) {
      if (formData[key] === '') {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; // Exit the function early if any field is empty
      }
    }
    try {
      const mobileNumber = formData.Mobile_Number;
      if (!/^\d{10}$/.test(mobileNumber)) {
        toast.error('Mobile number must be a 10-digit number!', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; 
      }
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      console.log(formData);
      const responseData = await response.json();

      console.log(responseData);

      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/user");
        }, 2000)

      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setShowAlert(true);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleUpdateSubmit = async () => {
    console.log('Inside handleUpdateSubmit');
    setLoading(true);


    try {
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          edit_user_id: rowData.user_id, // Include the company ID in the request
          Name: formData.Name,
          Mobile_Number: formData.Mobile_Number,
          RoleSelection: formData.RoleSelection,
          FireWorksName: formData.FireWorksName,
          User_Name: formData.User_Name,
          Password: formData.Password,
          nickname:formData.nickname,
        }),
      });

    
      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/user");
        }, 2000);
      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error('Error updating product:', error.message);
    }

    setLoading(false);
  };

  return (
    <div>
      <Container>
        <Row className='regular'>
          <Col lg='12' md='12' xs='12' className='py-3'>
            <PageNav pagetitle={`பயனர்${type === 'view' ? ' வியூ ' : type === 'edit' ? '  எடிட் ' : 'உருவாக்கம்'}`}></PageNav>
          </Col>

          <Col lg='4' md='6' xs='12' className='py-3'>
            {type === 'edit' ? (
              <TextInputForm
                placeholder={'பெயர்'}
                labelname={'பெயர்'}
                name='Name'
                value={formData.Name}
                onChange={(e) => handleChange(e, 'Name')}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={' பெயர்'}
                labelname={' பெயர்'}
                name='Name'
                value={type === "view" ? rowData.Name : formData.Name}
                onChange={(e) => handleChange(e, 'Name')}
              ></TextInputForm>
            )}
          </Col>
          <Col lg='4' md='6' xs='12' className='py-3'>
            {type === 'edit' ? (
              <DropDownUI
                optionlist={DropList}
                placeholder='பங்கு தேர்வு'
                labelname='பங்கு தேர்வு'
                name='RoleSelection'
                value={formData.RoleSelection}
                onChange={(updatedFormData) => setFormData({ ...formData, RoleSelection: updatedFormData.RoleSelection })}
              />
            ) : (
              <DropDownUI
                optionlist={DropList}
                placeholder='பங்கு தேர்வு'
                labelname='பங்கு தேர்வு'
                name='RoleSelection'
                value={type === "view" ? rowData.RoleSelection : formData.RoleSelection}
                onChange={(updatedFormData) => setFormData({ ...formData, RoleSelection: updatedFormData.RoleSelection })}
              />
            )}

          </Col>
          <Col lg='4' md='12' xs='12' className='py-3'>
            {type === 'edit' ? (
              <TextInputForm
                placeholder={'கைபேசி எண்'}
                type={'number'}
                labelname={'கைபேசி எண்'}
                name='Mobile_Number'
                value={formData.Mobile_Number}
                onChange={(e) => handleChange(e, 'Mobile_Number')}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={'கைபேசி எண்'}
                type={'number'}
                labelname={'கைபேசி எண்'}
                name='Mobile_Number'
                value={type === "view" ? rowData.Mobile_Number : formData.Mobile_Number}
                onChange={(e) => handleChange(e, 'Mobile_Number')}
              ></TextInputForm>
            )}

          </Col>
          <Col lg='3' md='6' xs='12' className='py-3'>
            {type === 'edit' ? (
              <TextInputForm
                placeholder={'பயனர் பெயர்'}
                labelname={'பயனர் பெயர்'}
                name='User_Name'
                value={formData.User_Name}
                onChange={(e) => handleChange(e, 'User_Name')}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={'பயனர் பெயர்'}
                labelname={'பயனர் பெயர்'}
                name='User_Name'
                value={type === "view" ? rowData.User_Name : formData.User_Name}
                onChange={(e) => handleChange(e, 'User_Name')}
              ></TextInputForm>
            )}

          </Col>
          <Col lg='3' md='6' xs='12' className='py-3'>
            {type === 'edit' ? (
              <TextInputForm
                placeholder={'Nick Name'}
                labelname={'Nick Name'}
                name='nickname'
                value={formData.nickname}
                onChange={(e) => handleChange(e, 'nickname')}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={'Nick Name'}
                labelname={'Nick Name'}
                name='nickname'
                value={type === "view" ? rowData.nickname : formData.nickname}
                onChange={(e) => handleChange(e, 'nickname')}
              ></TextInputForm>
            )}

          </Col>
          <Col lg='3' md='6' xs='12' className='py-3'>
            {type === 'view' ? null : (
              <TextInputForm
                placeholder={'கடவுச்சொல்'}
                suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
                labelname={'கடவுச்சொல்'}
                type={showPassword ? 'text' : 'password'}
                name='Password'
                value={formData.Password}
                onChange={(e) => handleChange(e, 'Password')}
              ></TextInputForm>)}
          </Col>

          <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
            <div className='text-center'>
              {type === "view" ? (
                <ClickButton label={<>back</>} onClick={() => navigate("/console/user")}></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                      </span>

                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className='mx-2'>
                        <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                      </span>
                      <span className='mx-2'>
                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {error && <Alert variant='danger' className='error-alert'>{error}</Alert>}
      </Container>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Body className='text-center'>
          <img src={require('../../components/sidebar/images/output-onlinegiftools.gif')} alt='Success GIF' />
          <p>User saved successfully!</p>
        </Modal.Body>
        <Modal.Footer>
          <ClickButton variant='secondary' label={<> Close</>} onClick={() => redirectModal()}>
            Close
          </ClickButton>
        </Modal.Footer>
      </Modal>
    </div >
  );
};

export default UserCreation;