import React, { useState, useEffect } from "react";
import { Container, Col, Row, Modal } from "react-bootstrap";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../../components/Table"; // Assume this is a table component to display the data
import { TextInputForm } from "../../components/Forms"; // Assume this is a custom input component
import { useMediaQuery } from "react-responsive";
import { ClickButton } from "../../components/ClickButton"; // Assume this is a button component
import MobileView from "../../components/MobileView"; // Assume this is for mobile display
import { useLocation } from "react-router-dom"; // For route location
import API_DOMAIN from "../../config/config"; // Your API domain
import { useTranslation } from 'react-i18next';

const Street = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    CustomerName: "",
    ReceiptNo: "",
  });
  const [productModal, setProductModal] = useState(false);
  const closeModal = () => setProductModal(false);
  const showModal = () => setProductModal(true);
  const [searchText, setSearchText] = useState("");
  const [englishInput, setEnglishInput] = useState("");
  const [tamilInput, setTamilInput] = useState("");

  // Function to translate English to Tamil dynamically
  const translateToTamil = (text) => {
    const translations = {
      "Product Name": "பொருட்களின் பெயர்",
      "Hello": "வணக்கம்",
      "Thank you": "நன்றி",
      // Add more static translations as needed
    };

    // If the text has a direct translation, return it; otherwise, just return the text
    const translated = translations[text] || text; 
    console.log(`Translating "${text}" to "${translated}"`); // Log translation for debugging
    return translated;
  };

  // Function to fetch data from the API
  const fetchData = async () => {
    setLoading(true); // Show loading indicator
    try {
      const response = await fetch(`${API_DOMAIN}/pawnjewelry.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: searchText,
        }),
      });

      const responseData = await response.json();
      setLoading(false); // Hide loading indicator

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.pawnjewelry.map((user) => ({
          ...user,
          jewel_product: JSON.parse(user.jewel_product || "[]"),
        }));

        // Filter data based on customer name and receipt number
        if (formData.CustomerName) {
          sortedData = sortedData.filter(
            (user) => user.customer_name === formData.CustomerName
          );
        }

        if (formData.ReceiptNo) {
          sortedData = sortedData.filter(
            (user) => user.recipt_no === formData.ReceiptNo
          );
        }

        sortedData.sort(
          (a, b) => parseInt(a.recipt_no) - parseInt(b.recipt_no)
        );
        setUserData(sortedData);
      } else {
        throw new Error(responseData.head.msg);
      }
    } catch (error) {
      setLoading(false); // Hide loading indicator on error
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, searchText]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleEnglishInputChange = (e) => {
    const value = e.target.value;
    setEnglishInput(value);
    setTamilInput(translateToTamil(value)); // Translate in real-time based on user input
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg="7" md="6" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list"></span>
            </div>
          </Col>
          <Col lg="5" md="6" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={showModal}
            />
          </Col>
          <Col lg="3" md="12" xs="12" className="py-1">
            <TextInputForm
              placeholder={t("Street Name")}
              onChange={handleSearch}
              prefix_icon={<FaMagnifyingGlass />}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {isMobile &&
                userData.map((user, index) => (
                  <MobileView
                    key={index}
                    sno={user.id}
                    name={user.recipt_no}
                    subname={user.customer_name}
                  />
                ))}
              <TableUI
                headers={[t("No"), t("Street Name"), t("தெரு  பெயர் ")]}
                body={userData}
                type="jewelPawning"
                pageview="yes"
                style={{ borderRadius: "5px" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={productModal} onHide={closeModal} backdrop="static" size="lg" centered>
        <Modal.Header>
          <Modal.Title>{t("Street")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
              <Col lg='6' className="py-3">
                <TextInputForm 
                  labelname={t("English - Street Name")} 
                  value={englishInput} 
                  onChange={handleEnglishInputChange} 
                />
              </Col>
              <Col lg='6' className="py-3">
                <TextInputForm 
                  labelname={t("Tamil")}
                  value={tamilInput} 
                  readOnly 
                />
              </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ClickButton label={t("Cancel")} onClick={closeModal} />
          <ClickButton label={t("Submit")} onClick={closeModal} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Street;
