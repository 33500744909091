import React from 'react'
import {  PDFViewer } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import JewelPawnPdf from './JewelPawnPdf';

const JewelPawnPreview = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  return(
    <PDFViewer width="100%" height="1000">
      <JewelPawnPdf data={rowData} />
    </PDFViewer>
  )
}
export default JewelPawnPreview