import React from 'react'
import { Button } from 'react-bootstrap'

const ClickButton = ({ label, onClick }) => {
  return (
    <>
      <Button className='create-btn' onClick={onClick}>{label} </Button>
    </>
  )
}
const Delete = ({ label, onClick }) => {
  return (
    <>
      <Button className='delete' onClick={onClick}>{label} </Button>
    </>
  )
}
const View = ({ label, onClick }) => {
  return (
    <>
      <Button className='delete' onClick={onClick}>{label} </Button>
    </>
  )
}

export { ClickButton, Delete,View } 